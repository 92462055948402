<ng-template #popUp let-modal>
    <div class="modal-header">
        <div class="heading" translate ngbAutofocus>
            {{popUpInfo.modelName}}
            <a class="filter-btn d-block" (click)="onFilterBy()" *ngIf="showFilter">
                <div class="d-inline-block"><i placement="bottom" tooltipclass="info-custom-tooltip" class="icon icon-Filter align-text-top"></i></div>
                <div class="d-inline-block">
                    Filter {{moduleName | titlecase }} page by {{filterData.name ==='segment' ? filterData.value[0].attributes.name : filterData.value}}
                </div> 
            </a>
        </div>
        <div class="d-flex align-items-center">
    <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')">
    </i>
    </div>
    </div>
    <div class="modal-body mt-3">
        <!-- Specific for Buzzwords -->
        <ng-container *ngIf="popUpInfo.callFromModule === 'category-content-quality'">
            <section class="buzzword-list-container d-flex flex-wrap gap-16 mb-2">
                <div class="p-1 m"*ngFor="let keyword of popUpInfo.moduleRawData.listOfBuzzword; let i = index">
                        <span class="buzzword-item cursor-pointer" [ngClass]="{'keyword-active me-1':i===activeBuzzword}" (click)="onKeywordClick(keyword,i)">#{{keyword}}</span>
                </div>
            </section>
            <!-- <button class="btn btn-primary btn-sm" (click)="[trackKeyword(selectedBuzzword),modal.dismiss('Cross click')]" translate><i class="icon icon-Location-plus"></i>Track Keyword</button> -->
        </ng-container>
        <!-- code end here -->
        <div class="info-banner d-flex align-items-center justify-content-between">
            <div class="start-content d-flex flex-column gap-2">
                <span *ngIf="popUpInfo.infoBanner.name" class="ellipsis" showTooltipOnTextOverflow [data]="popUpInfo.infoBanner.name | translate">{{popUpInfo.infoBanner.name}}</span>
                <span class="badgeView {{popUpInfo.infoBanner.subName?.class}}" *ngIf="popUpInfo.infoBanner.subName" [ngStyle]="popUpInfo.infoBanner.subName?.style" translate>{{popUpInfo.infoBanner.subName?.text}}</span>
                <section class="d-flex align-items-center mt-2 sub-details" *ngIf="popUpInfo.infoBanner?.showTrackBy && !popUpInfo.infoBanner?.harmonisedView">
                    <span class="header me-2">Segment:</span>
                    <span class="badgeView {{commonFunctions.differentBadges[popUpInfo.infoBanner.segment].class}}"
                        *ngIf="popUpInfo.infoBanner.segment;else showHyphen" translate>{{commonFunctions.differentBadges[popUpInfo.infoBanner.segment].name}}</span>
                    <ng-template #showHyphen>-</ng-template>
                    <span class="header ms-3">Tracked by:</span>
                    <ng-container *ngIf="popUpInfo.infoBanner?.trackedBy?.length;else showHyphen">
                        <div class="tracked-by-parent d-flex align-items-center main-content ms-2" ngbDropdown display="dynamic">
                            <div>{{(popUpInfo.infoBanner?.trackedBy[0].profile_type.split('')[0]|uppercase)}}</div>
                            <div class="separator">:</div>
                            <div class="profile-name text-truncate me-1"
                                [ngClass]="{'single-profile-name-width':popUpInfo.infoBanner?.trackedBy.length===1}"
                                showTooltipOnTextOverflow [data]="popUpInfo.infoBanner?.trackedBy[0].profile_name"
                                id="scorecardTrackedBy"></div>
                            <span class="link cursor-pointer" id="dropdownBasic1" ngbDropdownToggle
                                *ngIf="popUpInfo.infoBanner?.trackedBy.length>1">+{{popUpInfo.infoBanner?.trackedBy.length - 1}}</span>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown customTooltipPositionContainer">
                                <ng-container *ngFor="let tag of popUpInfo.infoBanner?.trackedBy.slice(1);let last = last">
                                    <div class="tracked-by-parent d-flex align-items-center child-content" [ngClass]="{'mb-1':!last}">
                                        <div>{{tag.profile_type.split('')[0]|uppercase}}</div>
                                        <div class="separator">:</div>
                                        <div class="profile-name text-truncate dropdown-profile-name-width customTooltipPosition" showTooltipOnTextOverflow
                                            [data]="tag.profile_name"></div>
                                    </div>
                                </ng-container>
                    
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #showHyphen>-</ng-template>
                </section>
            </div>
            <div class="end-content d-flex align-items-center gap-16">
                <div class="value-content" *ngIf="!popUpInfo.infoBanner.hidepercVal">
                    <div class="sub-1" *ngIf="(popUpInfo.infoBanner.value || productList?.meta?.total_count)" translate=""><span>
                        {{(popUpInfo.infoBanner.value || productList?.meta?.total_count) | numberSuffix  : 2}} 
                    </span>
                    {{popUpInfo.type==='store-grid' ? (popUpInfo.infoBanner.value === 1 || productList?.meta?.total_count === 1 ? 'Store' : 'Stores'):
                    (popUpInfo.type==='profile-grid') ? (popUpInfo.infoBanner.value === 1 || productList?.meta?.total_count === 1 ? 'Profile' : 'Profiles'):
                    (popUpInfo.type==='variant-grid') ? (popUpInfo.infoBanner.value === 1 || productList?.meta?.total_count === 1 ? 'Variant' : 'Variants'):
                    (popUpInfo.infoBanner.value === 1 || productList?.meta?.total_count === 1 ? 'Product' : 'Products')}}</div>
                    <div class="badgeView {{popUpInfo.infoBanner.subValue?.class}}" *ngIf="popUpInfo.infoBanner.subValue" [ngStyle]="popUpInfo.infoBanner.subValue?.style" translate>{{popUpInfo.infoBanner.subValue.text}}</div>
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-primary btn-sm" *ngIf="popUpInfo.infoBanner.meta?.trackBtn"
                        (click)="[trackKeyword(popUpInfo.callFromModule === 'category-content-quality' ? selectedBuzzword : popUpInfo.infoBanner.meta?.keyword),modal.dismiss('Cross click')]"><i
                            class="icon icon-Location-plus"></i>Track Keyword</button>
                    <app-common-action-dropdown [columns]="this.popUpInfo?.gridInfo?.customExportDetails?.sendColList ? [] : cols" [filename]="popupExportedFileName" [path]="popupExportPath"
                        [params]="popupExportParams" [withoutFilterPayload]="['sort']" [callFromPopUP]="true"
                        [isPopDownloadActive]="isPopDownloadActive" [showDropdownList]="{downloadReport: true,
                                        saveWidget: false}" [disableIcon]="this.popUpInfo?.exportAccess === false ? true : (NoData || loadingData)"></app-common-action-dropdown>
                </div>
            </div>
        </div>
        <div class="pop-up-grid-container mt-3" [ngClass]="{'custom-tab':popUpInfo.gridInfo.tabDetails?.isTabNeeded}">
            <ng-container *ngIf="popUpInfo.gridInfo.tabDetails?.isTabNeeded">
                <ul ngbNav #nav="ngbNav" [(activeId)]="popUpInfo.gridInfo.tabDetails.activeTabId" class="nav-tabs" class="nav-pills">
                    <ng-container *ngFor="let tab of popUpInfo.gridInfo.tabDetails.tabs; let i = index">
                        <li [ngbNavItem]=i>
                            <a ngbNavLink (click)="onTabChange(tab)">{{tab.display | translate}}</a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="ngGrid"></ng-template>
                            </ng-template>
                        </li>
                    </ng-container>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </ng-container>
            <ng-container *ngIf="!popUpInfo.gridInfo.tabDetails?.isTabNeeded">
            <ng-template [ngTemplateOutlet]="ngGrid"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #ngGrid>
    <app-ngx-grid-revamp [ngxCols]="cols" [ngxItems]="productList" [moduleType]="moduleType" [column]="cols"
    [collection]="productList" [loadingData]="loadingData" [noData]="NoData" (sortData)="sortData($event)"
    [itemPerPage]='itemPerPage' [isPaginationWithoutRoute]=true (paginationNo)="onPageChange($event)"
    (paginationSize)="onPageSizeChange($event)" [ngClass]="'common-popup-grid '+customClass" [showPopUpPageSize]="true"></app-ngx-grid-revamp>
</ng-template>