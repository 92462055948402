<div class="reset-password-container d-flex justify-content-center align-items-center"> 
    <div class="digital-shelf-logo">
        <img height="3.75rem" width="5.875rem" src="../../../../assets/images/DS logo.svg" alt="" />
      </div>
    <div class="inner-container">
        <div class="mb-4 d-flex justify-content-between align-items-center">
            <h4 class="modal-title" translate ngbAutoFocus>Reset Password</h4>
        </div>
        <form [formGroup]="resetPasswordForm" class="resetPasswordForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" autocomplete="off">
                <div class="input-container d-flex align-items-center justify-content-between mb-4"> 
                    <label for="email" translate>Email Id <sup>*</sup></label> 
                    <input type="text"
                        id="email" placeholder="{{'Enter Email' | translate}}" autocomplete="off"
                        formControlName="email" />
                </div>
                <div class="input-container mb-4"> 
                    <div class="d-flex align-items-center justify-content-between ">
                        <label for="newPassword" translate>New Password <sup>*</sup></label> 
                        <input
                            [type]="displayPassword['newState']?'password':'text'" id="new-password" placeholder="{{'Enter New Password' | translate}}" autocomplete="new-password"
                            [ngClass]="{ error: resetPasswordForm.controls['new_password'].invalid && (resetPasswordForm.controls['new_password'].dirty || resetPasswordForm.controls['new_password'].touched || formSubmitted) }"
                            formControlName="new_password" 
                        />
                        <span (click)="showPasswordFunc('newState')" [ngClass]="displayPassword['newState'] ? 'icon-Eye' : 'icon-Eye-closed'"
                        class="icon display-password form-control-icon"></span>
                </div>
                    <div class="errorLable"
                        *ngIf=" resetPasswordForm.controls['new_password'].invalid && (resetPasswordForm.controls['new_password'].dirty || resetPasswordForm.controls['new_password'].touched || formSubmitted) ">
                        <span *ngIf="resetPasswordForm.controls['new_password'].errors.required"
                            translate>New Password Required</span>
                            <span *ngIf="resetPasswordForm.controls['new_password'].errors.fieldMessage" translate>{{resetPasswordForm.controls['new_password'].errors.fieldMessage}}</span>
                        <span
                            *ngIf="resetPasswordForm.controls['new_password'].errors['newError']" translate>{{resetPasswordForm.controls['new_password'].errors['newError']}}</span>
                    </div>
                </div>
                <div class="input-container mb-4"> 
                    <div class="d-flex align-items-center justify-content-between">
                    <label for="confirmPassword" translate>Confirm Password <sup>*</sup></label> 
                    
                    <input
                        [type]="displayPassword['confirmState']?'password':'text'" id="confirm-password" placeholder="{{'Retype New Password' | translate}}" autocomplete="confirm-password"
                        [ngClass]="{ error: resetPasswordForm.controls['confirm_password'].invalid && (resetPasswordForm.controls['confirm_password'].dirty || resetPasswordForm.controls['confirm_password'].touched || formSubmitted) }"
                        formControlName="confirm_password" />
                        <span (click)="showPasswordFunc('confirmState')" [ngClass]="displayPassword['confirmState'] ? 'icon-Eye' : 'icon-Eye-closed'"
                        class="icon display-password form-control-icon"></span>
                    </div>
                    <div class="errorLable"
                        *ngIf=" resetPasswordForm.controls['confirm_password'].invalid && (resetPasswordForm.controls['confirm_password'].dirty || resetPasswordForm.controls['confirm_password'].touched || formSubmitted) ">
                        <span *ngIf="resetPasswordForm.controls['confirm_password'].errors.required"
                            translate>Confirm Password Required</span>
                        <span *ngIf="resetPasswordForm.controls['confirm_password'].errors['confirmError']" translate>{{resetPasswordForm.controls['confirm_password'].errors['confirmError']}}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary btn-lg" translate>Update Password</button>
                </div>
        </form>
    </div>
</div>
<router-outlet></router-outlet>
