<div class="grid-container">
    <div class="box-container box-view"
        *ngFor="let prod of ngxItems.data" (click)="quickOpen.emit(prod)">
        <div class="d-inline-flex gap-22 flex-wrap mt-2">
            <span *ngFor="let type of prod.attributes?.product_type" class="badge-without-color" [ngClass]="MatchesTypeClass[type]" translate>{{MatchesType[type]}}</span>
        </div>
        <div class="box-container-inner">
            <div class="box-item-col gap-63" *ngIf="!isStore">
                <div class="img-container d-inline-block align-middle">
                    <img *ngIf="prod?.attributes?.images && prod?.attributes?.images.length > 0"
                        [src]="prod?.attributes?.images[0]" alt="Product Image">
                    <img *ngIf="prod?.attributes?.images && prod?.attributes?.images.length == 0"
                        src="../../../../assets/images/no-image.svg" alt="Product Image">
                </div>
                <div class="d-inline-block name-container prod-name-bv">
                    <h3 class="mb-0 available-heading cursor-pointer"
                        showMultiTooltipOnTextOverflow [data]="prod?.attributes?.name | translate">
                    </h3>
                    <div class="showVariants" *ngIf="prod?.attributes?.variant_count">
                        <div ngbDropdown class="d-inline-block" placement="bottom-left"
                            class="note-text available-dropdown">
                            <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">{{'In' | translate}}
                                {{(prod?.attributes?.variant_count)}} {{'Variants' | translate}}</span>
                        </div>
                    </div>
                    <div class="showVariants emptyVariant" *ngIf="!prod?.attributes?.variant_count"></div>
                    <div>
                        <ng-template #starLabel>
                            <span class="star">&#x02605;</span>
                        </ng-template>
                        <div class="ratingLabel mt-1"
                            [ngClass]="commonFunctions.getStarStatus(prod?.attributes?.rating)"
                            *ngIf="prod?.attributes?.rating">
                            {{prod?.attributes?.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1">
                            </ngb-rating>
                        </div>
                        <div class="card-view-rating"
                            *ngIf="!prod?.attributes?.rating"></div>
                    </div>
                </div>
            </div>
            <div class="box-item-col" *ngIf="!isStore"> 
                <span class="info-text d-inline-block" translate>Store Name</span>
                <h3 class="mb-0 available-heading cursor-pointer"
                    showMultiTooltipOnTextOverflow [data]="prod?.attributes?.store_name | translate">
                </h3>
            </div>
            <div class="box-item-col mb-3 mt-1">
                <span class="info-text d-inline-block"
                    translate>{{ isStore ? 'Average Price' : 'Price'}}</span>
                <div class="gap-8 d-inline-flex align-items-center">
                    <span class="price-value mb-0"
                        [ngClass]="{'empty-price': !prod?.attributes?.price}">{{prod?.attributes?.price
                        ? (prod?.attributes?.currency?.symbol || commonFunctions.currencySelected?.attributes?.symbol) : null}}{{prod?.attributes?.price ?
                        (prod?.attributes?.price | numberSuffix: 2) : '-'}} </span>
                    <span class="delta-content" *ngIf="prod?.attributes?.price_change"
                        [ngClass]="{'negative':prod?.attributes?.price_change>0}"><i
                            class="icon icon-Triangle-Down1"></i>{{prod?.attributes?.currency?.symbol}}{{(commonFunctions.getAbsoluteValue(prod?.attributes?.price_change)
                        | numberSuffix: 2)}}
                        {{prod?.attributes?.price_change>0?("Higher" | translate):("Lower" | translate)}}</span>
                </div>
            </div>
            <div class="box-item-col mb-3" *ngIf="!isStore">
                <span class="info-text d-inline-flex align-items-center w-50" translate>Discount</span>
                <span *ngIf="prod?.attributes?.discount"
                    class="voucher-badge d-inline-block">{{prod?.attributes?.discount}}% {{'OFF' | translate}}</span>
                <span *ngIf="!prod?.attributes?.discount"
                    class="box-value-text voucher-null d-inline-flex align-items-center">-</span>
            </div>
            <div class="box-item-col mb-3" *ngIf="!isStore">
                <span class="info-text d-inline-flex align-items-center w-50" translate>Vouchers</span>
                <ng-template [ngIf]="prod?.attributes?.vouchers && prod?.attributes?.vouchers?.length > 0">
                    <span class="box-value-text d-flex align-items-center gap-2 promotions">
                        <span class="voucher-badge ellipsis d-block" showTooltipOnTextOverflow [data]="prod?.attributes?.vouchers[0]"></span>
                        <span *ngIf="prod?.attributes?.vouchers?.length>1">+{{prod?.attributes?.vouchers?.length-1}}</span>
                    </span>
                </ng-template>
                <span class="box-value-text voucher-null d-inline-flex align-items-center"
                    *ngIf="!prod?.attributes?.vouchers || prod?.attributes?.vouchers?.length == 0">
                    <span>
                        -
                    </span>
                </span>
            </div>
            <div class="box-item-col mb-3" *ngIf="!isStore">
                <span class="info-text d-inline-block"
                    translate>Store Name
                </span>
                <div class="d-flex align-items-center gap-8">
                    <span showTooltipOnTextOverflow [data]="prod?.attributes?.store" class="box-value-text-store"></span>
                    <span class="badgeView {{commonFunctions.differentBadges[prod?.attributes?.segment].class}}" translate>
                        {{commonFunctions.differentBadges[prod?.attributes?.segment].name | translate}}</span>
                </div>
            </div>
            <!-- <div class="box-item-col desc">
                <span class="info-text d-inline-block" translate>Description</span>
                <span showMultiTooltipOnTextOverflow [ttPosition]="'available-product-tt'"
                    [data]="this.commonFunctions.extractTextFromHtml(prod?.attributes?.description) | translate" class="box-value-text-2 cursor-pointer"></span>
            </div> -->
            <div class="box-item-col mb-3" *ngIf="!isStore"> <span class="info-text d-inline-block"
                    translate>Category</span>
                <span showTooltipOnTextOverflow [data]="prod?.attributes?.category"
                    class="box-value-text cursor-pointer"></span>
            </div>
            <div class="box-item-col mb-3" *ngIf="isStore">
                <span class="info-text d-inline-block"
                    translate>Average Ratings</span>
                <ng-template #starLabel>
                    <span class="star">&#x02605;</span>
                </ng-template>
                <div class="ratingLabel mt-1"
                    [ngClass]="commonFunctions.getStarStatus(prod?.attributes?.rating)"
                    *ngIf="prod?.attributes?.rating">
                    {{prod?.attributes?.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1">
                    </ngb-rating>
                </div>
                <div class="card-view-rating"
                    *ngIf="!prod?.attributes?.rating"></div>
            </div>
            <div class="box-item-col mb-3 align-items-center"> <span class="info-text d-inline-block"
                    translate>Channel
                </span>
                <div class="d-flex align-items-center gap-6">
                    <app-marketplace-store-logo [storeName]="prod?.attributes?.marketplace || prod?.attributes?.marketplace_name
                    ">
                    </app-marketplace-store-logo>
                    <span class="marketplace-name cursor-pointer" showTooltipOnTextOverflow
                        [data]="prod?.attributes?.marketplace || prod?.attributes?.marketplace_name"></span>
                </div>
            </div>
            <div class="box-item-col mb-3">
                <span class="info-text header" translate>Tracked by</span>
                <ng-container *ngIf="prod.attributes?.tracked_by?.length;else showHyphen">
                    <div class="tracked-by-parent d-flex align-items-center main-content" ngbDropdown display="dynamic">
                        <div>{{(prod.attributes.tracked_by[0].profile_type.split('')[0]|uppercase)}}</div>
                        <div class="separator">:</div>
                        <div class="profile-name text-truncate"
                            [ngClass]="{'single-profile-name-width':prod.attributes.tracked_by.length===1}"
                            showTooltipOnTextOverflow [data]="prod.attributes?.tracked_by[0].profile_name"
                            id="scorecardTrackedBy"></div>
                        <span class="link cursor-pointer" id="dropdownBasic1" ngbDropdownToggle
                            *ngIf="prod.attributes?.tracked_by.length>1">+{{prod?.attributes.tracked_by.length - 1}}</span>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown">
                            <ng-container *ngFor="let tag of prod.attributes.tracked_by.slice(1);let last = last">
                                <div class="tracked-by-parent d-flex align-items-center child-content" [ngClass]="{'mb-1':!last}">
                                    <div>{{tag.profile_type.split('')[0]|uppercase}}</div>
                                    <div class="separator">:</div>
                                    <div class="profile-name text-truncate dropdown-profile-name-width"
                                        showTooltipOnTextOverflow [data]="tag.profile_name"></div>
                                </div>
                            </ng-container>
        
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="box-item-col mb-3">
                <span class="info-text d-inline-block" translate>Last Updated</span>
                <span class="box-value-text">
                    {{commonFunctions.formatSimpleDate(prod?.attributes?.updated_at || prod?.attributes?.last_updated)}}
                </span>
            </div>
            <div class="box-item-col">
                <div class="d-inline-block w-50">
                    <button class="btn icon-only-btn"
                        [disabled]="!permissionForNotification.write_access" (click)="noteOpen.emit({prod: prod, isStore: isStore})"
                        placement="{{commonFunctions.rtlStatus ? 'left' :'right'}}"
                        ngbTooltip="{{'Create Notification' | translate}}" tooltipClass="info-custom-tooltip">
                        <i class="icon icon-Notification-on"></i>
                    </button>
                </div>
                <div class="d-flex align-items-center justify-content-end gap-8">
                    <button class="btn btn-secondary btn-lg" (click)="$event.stopPropagation();trendCompareOpen.emit({module:'available_products',meta:prod})" translate>
                        Trend Compare
                    </button>
                    <button class="btn btn-primary btn-lg" (click)="$event.stopPropagation();deepOpen.emit(prod)" translate>
                        Deep Compare
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #showHyphen>-</ng-template>